import { useEffect, useState } from "react";

export const useExternalScript = (url, isAuth) => {
  const [state, setState] = useState(url ? "loading" : "idle");

  useEffect(() => {
    if (!url && !isAuth) {
      setState("idle");
      return;
    }

    let script = document.querySelector(`script[src="${url}"]`);

    const handleScript = (event) => {
      setState(event.type === "load" ? "ready" : "error");
    };

    if (!script) {
      script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true; // Set async attribute to load the script asynchronously
      script.defer = true;
      if (
        url !=
        "https://maps.googleapis.com/maps/api/js?key=&libraries=geometry,drawing&callback=initMap"
      ) {
        script.onload = handleScript; // Handle script onload event
        document.head.appendChild(script);
      }
      // script.addEventListener("load", handleScript);
      // script.addEventListener("error", handleScript);
    } else {
      // If the script is already loaded, set state to "ready"
      setState("ready");
    }

    // script.addEventListener("load", handleScript);
    // script.addEventListener("error", handleScript);

    return () => {
      script.removeEventListener("load", handleScript);
      // script.removeEventListener("error", handleScript);
    };
  }, [url, isAuth]);

  return state;
};
