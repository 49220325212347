import React from 'react'
import { bottomCardStyles, cashSvgStyle, strongTextStyle, thinTextStyle } from '../RideTracking'


// Mui imports
import { Avatar, Box, Stack, Typography } from "@mui/material";

// Assets imports
import VerificationSvg from "../../assets/Verification.svg"

import StarSvg from "../../assets/Star.svg";
import ReceiptSvg from "../../assets/Receipt.svg";
import CashSvg from "../../assets/cashIcon.svg";


// Other imports

import Destination from "./Destination";
import Banner from "./Banner";


const OngoingPhaseBottomCard = ({apiResponse,dropOffTime }) => {
  return (
    <Box
      sx={{
        flex: 1.5,
        overflowY: "scroll",
      }}
    >
      {apiResponse?.status === "ONGOING" ? (
        <>
          {/* Ride fare Card */}

          <Box
            sx={{
              backgroundColor: "#fff",
              marginBottom: "20px",
              padding: "20px",
            }}
          >
            <Destination
              dropOffTime={dropOffTime}
              dropOfAddress={
                apiResponse?.dropoff_location?.address_text
              }
              stops={apiResponse?.stops}
            />
          </Box>

          <div
            style={{
              backgroundImage: `linear-gradient(to bottom, #FFF 60%, transparent 0%), url(${ReceiptSvg})`,
              backgroundRepeat: "repeat-x",
              // width: "100%",
              height: "5%",
              marginBottom: "20px",
              padding: "20px",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Stack
              width={"100vw"}
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Typography
                variant="h6"
                sx={{
                  color: "var(--font-light-70, rgba(15, 24, 40, 0.70))",
                  textAlign: "center",
                  fontFamily: "Proxima Nova",
                  fontSize: { xs: "15px", md: "18px" },
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "normal",
                }}
              >
                TOTAL FARE
              </Typography>
              <Stack direction="row" width={"auto"} gap={"5px"}>
                <img
                  src={CashSvg}
                  alt="cash-svg"
                  style={cashSvgStyle}
                  width={"18px"}
                />
                <Typography variant="h6" style={strongTextStyle}>
                  ₱{apiResponse?.payment_amount ?? ""}
                </Typography>
              </Stack>
            </Stack>
          </div>

          {/* Driver info card */}
          <div
            style={{
              ...bottomCardStyles,
              height: "auto",
              // marginBottom: "20px",
            }}
          >
            {/* <div style={bottomCardInnerStyles}>
          <div
            style={{ ...groupCircleImagesStyles, marginRight: "20px" }}
          >
            <Avatar
              style={{ width: "80px", height: "80px", marginTop: "10px" }}
              src={apiResponse?.driver_details?.driver_image_url}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h6" style={strongTextStyle}>
              {apiResponse?.driver_details?.full_name}
              <img
                src={StarSvg}
                alt="rating-star-svg"
                style={{
                  width: "30px",
                  height: "30px",
                  marginLeft: "20px",
                  marginTop: "10px",
                  marginRight: "10px",
                }}
              />
              {apiResponse?.driver_details?.avg_rating?.toFixed(1)}
            </Typography>
            <Typography variant="body2" style={thinTextStyle}>
              {apiResponse?.driver_details?.car_company},{" "}
              {apiResponse?.driver_details?.car_registration_id}
            </Typography>
          </div>
        </div> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "100%",
                height: "fit-content",
              }}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap="15px"
                height={"100%"}
              >
                <Stack gap={"10px"} height={"100%"}>
                  <Avatar
                    style={{
                      width: "52px",
                      height: "52px",
                      // marginTop: "10px",
                    }}
                    src={apiResponse?.driver_details?.driver_image_url}
                  />
                  <Stack direction={"row"} alignItems={"center"}>
                    <img
                      src={StarSvg}
                      alt="rating-star-svg"
                      style={{
                        width: "30px",
                        height: "30px",
                        // marginLeft: "20px",
                        // marginTop: "10px",
                        // marginRight: "10px",
                      }}
                    />
                    <Typography>
                      {apiResponse?.driver_details?.avg_rating?.toFixed(
                        1
                      )}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack gap={"5px"}>
                  {/* {apiResponse?.driver_details?.car_color && (
                <Typography variant="body2" style={thinTextStyle}>
                  {apiResponse?.driver_details?.car_color ?? ""}
                </Typography>
              )}

              {apiResponse?.driver_details?.car_color && (
                <Box sx={dotContainerStyle}>
                  <Box sx={dotCss}></Box>
                </Box>
              )} */}

                  <Typography
                    variant="body2"
                    style={{
                      ...thinTextStyle,
                      fontWeight: "bold",
                      color: "#0F1828",
                    }}
                  >
                    {apiResponse?.driver_details?.full_name}
                  </Typography>

                  <Stack gap={"6px"}>
                    {apiResponse?.driver_details?.car_color && (
                      <Typography
                        variant="body2"
                        style={{
                          ...thinTextStyle,
                          fontWeight: 500,
                        }}
                      >
                        {apiResponse?.driver_details?.car_color ?? ""}
                      </Typography>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          ...thinTextStyle,
                          fontWeight: 500,
                        }}
                      >
                        {apiResponse?.driver_details?.car_company}
                      </Typography>
                      <div
                        style={{
                          width: "2px",
                          padding: "0 0",
                          margin: "auto 5px",
                          height: "65%",
                          backgroundColor: "#0F1828B2",
                        }}
                      ></div>

                      {apiResponse?.driver_details
                        ?.car_model_number && (
                        <Typography
                          variant="body2"
                          style={{
                            ...thinTextStyle,
                            fontWeight: 500,
                          }}
                        >
                          {apiResponse?.driver_details
                            ?.car_model_number ?? ""}
                        </Typography>
                      )}
                    </Box>
                  </Stack>

                  <Typography
                    variant="body2"
                    style={{
                      ...thinTextStyle,
                      fontWeight: 500,
                    }}
                  >
                    {apiResponse?.driver_details?.car_registration_id}
                  </Typography>
                </Stack>
              </Stack>
              <div style={{alignSelf:"flex-start", width:"70px" , height :"59px" }}>
              <img  src={VerificationSvg} />
            </div>
            </Box>
            
          </div>

          <Banner url={apiResponse?.banner_url} />
        </>
      ) : (
        <></>
      )}
    </Box>
  )
}

export default OngoingPhaseBottomCard