// React imports
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
// Mui imports
import { Avatar, Box, Stack, Typography } from "@mui/material";

// Assets imports

import CarTrackSvg from "../assets/CarTrack.svg";

import pinPointSvg from "../assets/ridetracking/Pin icon.svg";
import drop from "../assets/ridetracking/Drop Location.svg";

// Other imports
import axios from "axios";

import logoSvg from "../assets/PeekupBlueCar.webp";
import { formatDateWithTime } from "../utils/formateDate";
import BottomCardHeader from "./ridetracking/BottomCardHeader";

import RequestedPhaseBottomCard from "./ridetracking/RequestedPhaseBottomCard";
import OngoingPhaseBottomCard from "./ridetracking/OngoingPhaseBottomCard";
import LottieIframe from "./ridetracking/rideLoading";

// Function to handle the polyline
const handlePolyline = (
  apiResponse,
  map,
  markers,
  setMarkers,
  driverLocation,
  destinationLocation
) => {
  // Decode the polyline path from the API response
  const decodedPath = window.google.maps.geometry.encoding.decodePath(
    apiResponse?.encoded_polyline
  );

  // Create or update the polyline
  if (!markers?.polylineObj) {
    const polyline = new window.google.maps.Polyline({
      path: decodedPath,
      geodesic: true,
      strokeColor: "#F98244",
      strokeOpacity: 1.0,
      strokeWeight: 4,
    });

    // Set the polyline on the map
    polyline.setMap(map);

    // Update the markers state with the new polyline object
    setMarkers((prev) => ({ ...prev, polylineObj: polyline }));
  } else {
    // Update the existing polyline path
    markers.polylineObj.setPath(decodedPath);
  }

  // Create a new LatLngBounds object
  const bounds = new window.google.maps.LatLngBounds();

  // Extend the bounds with driver and destination locations
  bounds.extend(driverLocation);
  bounds.extend(destinationLocation);

  // Extend the bounds with decoded polyline path coordinates
  decodedPath.forEach((coord) => bounds.extend(coord));

  // Fit the map to the bounds
  map.fitBounds(bounds);
};

const GoogleMap = ({ apiResponse }) => {
  const [map, setMap] = useState(null);
  const [stops, setStops] = useState({});
  const [trackDestinationMarker, setTrackDestinationMarker] = useState("");
  const [markers, setMarkers] = useState({
    destinationMarkerObj: null,
    driverMarkerObj: null,
    polylineObj: null,
  });
  // Initializing a map
  useEffect(() => {
    const pickupLocation = {
      lat: 14.634624437853013,
      lng: 121.03432457373226,
    };
    const initialCenter = pickupLocation;
    const initialZoom = 12;
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: initialCenter,
      zoom: initialZoom,
      mapTypeControl: false,
      gestureHandling: "cooperative",
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
    });
    setMap(map);
  }, []);

  useEffect(() => {
    if (
      !map ||
      !apiResponse ||
      ["COMPLETED", "CANCELLED"].includes(apiResponse.status)
    )
      return;
    if (Object.keys(apiResponse)?.length > 0) {
      const pickupLocation = {
        lat: apiResponse?.pickup_location?.latitude,
        lng: apiResponse?.pickup_location?.longitude,
      };

      const driverLocation = {
        lat: apiResponse?.driver_details?.driver_location?.latitude,
        lng: apiResponse?.driver_details?.driver_location?.longitude,
      };

      const dropoffLocation = {
        lat: apiResponse?.dropoff_location?.latitude,
        lng: apiResponse?.dropoff_location?.longitude,
      };

      const destinationLocation =
        apiResponse?.status === "REQUESTED" ? pickupLocation : dropoffLocation;

      const destinationAddressText =
        apiResponse?.status === "REQUESTED"
          ? apiResponse?.pickup_location?.address_text
          : apiResponse?.dropoff_location?.address_text;

      const driverMarker = {
        url: CarTrackSvg,
        scaledSize: new window.google.maps.Size(40, 40),
      };

      const destinationIcon =
        apiResponse?.status === "REQUESTED" ? pinPointSvg : drop;

      const destinationMarker = {
        url: destinationIcon,
        scaledSize:
          apiResponse?.status === "REQUESTED"
            ? new window.google.maps.Size(40, 40)
            : new window.google.maps.Size(20, 20),
      };

      if (!markers?.driverMarkerObj) {
        //Create markers
        console.log("Create driver marker");
        const driverMarkerObj = new window.google.maps.Marker({
          position: driverLocation,
          map,
          title: "Driver Location",
          icon: driverMarker,
        });

        setMarkers((prev) => ({ ...prev, driverMarkerObj: driverMarkerObj }));
      } else {
        const driverLatLng = new window.google.maps.LatLng(
          driverLocation.lat,
          driverLocation.lng
        );
        // Update marker position
        markers?.driverMarkerObj?.setPosition(driverLatLng); // Assuming driverLatLng is defined

        // Ensure the marker is visible on the map
        markers?.driverMarkerObj?.setMap(map);
      }

      if (apiResponse?.stops?.length > 0 && apiResponse?.status === "ONGOING") {
        const data = apiResponse.stops.reduce((acc, stop) => {
          const position = {
            lat: stop.latitude,
            lng: stop.longitude,
          };

          const destinationMarkerObj = new window.google.maps.Marker({
            position: position,
            map: map,
            title: "Dropoff Location",
            icon: {
              url: drop,
              scaledSize: new window.google.maps.Size(20, 20),
            },
          });

          acc[stop.order] = destinationMarkerObj;
          return acc; // Make sure to return the accumulator for the next iteration
        }, {});

        setStops(data);
      }
      if (!markers?.destinationMarkerObj) {
        // Create destination marker
        console.log("Create destination marker");
        setTrackDestinationMarker(apiResponse?.status);
        const destinationMarkerObj = new window.google.maps.Marker({
          position: destinationLocation,
          map: map,
          title: "Dropoff Location",
          icon: destinationMarker,
        });

        // On hover destination marker obj show address text
        const destinationMarkerInfo = new window.google.maps.InfoWindow({
          content: destinationAddressText,
        });

        // Add click event listener to open info window
        destinationMarkerObj.addListener("click", () => {
          destinationMarkerInfo.open(map, destinationMarkerObj);
        });

        setMarkers((prev) => ({
          ...prev,
          destinationMarkerObj: destinationMarkerObj,
        }));
      } else {
        const destinationLatLng = new window.google.maps.LatLng(
          destinationLocation.lat,
          destinationLocation.lng
        );

        // Checking the response, removing the old marker, and setting a new one.
        if (trackDestinationMarker !== apiResponse?.status) {
          setTrackDestinationMarker(apiResponse?.status);
          markers?.destinationMarkerObj?.setMap(null);

          const destinationMarkerObj = new window.google.maps.Marker({
            position: destinationLocation,
            map: map,
            title: "Dropoff Location",
            icon: destinationMarker,
          });

          // On hover destination marker obj show address text
          const destinationMarkerInfo = new window.google.maps.InfoWindow({
            content: destinationAddressText,
          });

          // Add click event listener to open info window
          destinationMarkerObj.addListener("click", () => {
            destinationMarkerInfo.open(map, destinationMarkerObj);
          });

          setMarkers((prev) => ({
            ...prev,
            destinationMarkerObj: destinationMarkerObj,
          }));
        } else {
          // Update destination marker position
          markers?.destinationMarkerObj?.setPosition(destinationLatLng); // Assuming destinationLatLng is defined

          // Ensure the destination marker is visible on the map
          markers?.destinationMarkerObj?.setMap(map);
          if (
            markers?.destinationMarkerInfo &&
            markers?.destinationMarkerInfo.getContent() !==
              destinationAddressText
          ) {
            markers?.destinationMarkerInfo.setContent(destinationAddressText);
          }
        }
      }

      // Decode the polyline and create a Polyline object
      // const decodedPath = window.google.maps.geometry.encoding.decodePath(
      //   apiResponse?.encoded_polyline
      // );
      // const polyline = new window.google.maps.Polyline({
      //   path: decodedPath,
      //   geodesic: true,
      //   strokeColor: "#F98244",
      //   strokeOpacity: 1.0,
      //   strokeWeight: 8,
      // });

      // if (!markers?.polylineObj) {
      //   polyline.setMap(map);

      //   setMarkers((prev) => ({ ...prev, polylineObj: polyline }));
      // } else {
      //   markers?.polylineObj.setPath(decodedPath);
      // }
      // const bounds = new window.google.maps.LatLngBounds();
      // bounds.extend(driverLocation);
      // bounds.extend(destinationLocation);
      // decodedPath.forEach((coord) => bounds.extend(coord));
      // map.fitBounds(bounds);

      handlePolyline(
        apiResponse,
        map,
        markers,
        setMarkers,
        driverLocation,
        destinationLocation,
        apiResponse?.stops
      );
    }

    return () => {
      if (markers.driverMarkerObj) {
        markers.driverMarkerObj.setMap(null);
      }

      if (markers.destinationMarkerObj) {
        markers.destinationMarkerObj.setMap(null);
      }
    };
  }, [apiResponse, map]);

  return (
    <div
      id="map"
      style={{
        width: "100%",
        maxWidth: "480px",
        margin: "auto",
        height: "100%",
      }}
    ></div>
  );
};

export let strongTextStyle = {
  fontFamily: "Proxima Nova",
  fontSize: "30px",
  fontStyle: "normal",
  fontWeight: 800,
  lineHeight: "normal",
  letterSpacing: "0.027px",
  textTransform: "capitalize",
  color: "var(--font-dark, #0F1828)",
};

export let thinTextStyle = {
  fontFamily: "Proxima Nova",
  fontSize: "19px",
  fontStyle: "normal",
  textTransform: "capitalize",
  fontWeight: 100,
  lineHeight: "20px",
  letterSpacing: "0.027px",
  color: "var(--font-light-70, rgba(15, 24, 40, 0.70))",
};
export let bottomCardStyles = {
  height: "11%",
  background: "#FFF",
  color: "var(--font-dark, #0F1828)",
  display: "flex",
  gap: "8px",
  flexDirection: "column",
  // alignItems: "flex-start",
  padding: "20px",
  marginBottom: "20px",
};
export let bottomCardInnerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: "10px",
};

export let groupCircleImagesStyles = {
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px",
  marginRight: "18px",
  height: "100%",
};

export let driverImageStyle = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  backgroundColor: "gray",
  // marginRight: "",
  // boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
};
export let starStyle = {
  width: "30px",
  height: "30px",
  // marginLeft: "20px",
  // marginTop: "10px",
  // marginRight: "10px",
};
export let cashSvgStyle = {
  width: "35px",
  height: "35px",
  // marginLeft: "50vw",
  // marginRight: "5px",
};
const RideTracking = () => {
  const [isLoading, setisLoading] = useState(false);
  // const [isMobile, setIsMobile] = useState(window?.innerWidth <= 600);
  //Styles
  let googleMapStyle = {
    flex: 1, // Allow the map container to take up available space
    display: "flex",
    flexDirection: "column", // To stack the map and data card vertically on small screens
    justifyContent: "center", // Center the map horizontally
  };

  //Change styles as per mobile screen
  if (true) {
    driverImageStyle = {
      ...driverImageStyle,
      height: "50px",
      width: "50px",
    };

    strongTextStyle = {
      ...strongTextStyle,
      fontSize: "20px",
    };

    thinTextStyle = {
      ...thinTextStyle,
      fontSize: "17px",
    };

    starStyle = {
      ...starStyle,
      width: "20px",
      height: "20px",
      // marginLeft: "10px",
    };

    cashSvgStyle = {
      ...cashSvgStyle,
      width: "25px",
      height: "25px",
      // marginLeft: "40vw",
    };
  }

  const { ride_uuid } = useParams();
  const [apiResponse, setApiResponse] = useState({});

  const [dropOffTime, setDropOffTime] = useState(null);
  const timeRef = useRef(null);

  //Alert bar states
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const getStatusText = (status, APIResponse) => {
    const dateObject = formatDateWithTime(APIResponse?.updated_at);
    switch (status) {
      case "COMPLETED":
        return `Your ride with this booking ID (${
          apiResponse?.booking_id ?? ""
        }) has been successfully completed on (${dateObject})`;
      case "CANCELLED":
        return `Your ride with this booking ID (${
          apiResponse?.booking_id ?? ""
        }) was cancelled on (${dateObject})`;
      // Add more cases as needed for other statuses
      default:
        return "Invalid status";
    }
  };

  const isStatusValid = ["COMPLETED", "CANCELLED"].includes(
    apiResponse?.status
  );

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window?.innerWidth <= 600);
  //   };

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const config = useSelector(
    (store) => store.config.configValues.server_base_url
  );

  useEffect(() => {
    const fetchData = () => {
      if (config) {
        axios
          .get(`${config}/crm/ride/tracking/${ride_uuid}/`)
          .then((response) => {
            const data = response.data;
            setApiResponse(data?.data);
            let dropTime = new Date();
            dropTime.setMinutes(dropTime.getMinutes() + data?.data?.eta_in_min);
            dropTime = dropTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });
            setDropOffTime(dropTime);

            //Raise alert
            setOpen(true);
            setAlertType("success");
            setAlertMessage(data?.message);
          })
          .catch((error) => {
            //Redirect to not found page
            // window.location.href = "/not-found/";
          })
          .finally(() => {
            setTimeout(() => {
              setisLoading(false);
            }, 500);
          });
      }
    };

    //Continue hit until apiresponse got set
    if (Object.keys(apiResponse)?.length <= 0) {
      setisLoading(true);
      fetchData();
    }
    //Hit and update data every 1min
    else {
      timeRef.current = setTimeout(() => {
        fetchData();
      }, 1000 * 30);
    }

    return () => {
      clearTimeout(timeRef.current);
    };
  }, [apiResponse, ride_uuid]);

  return (
    <>
      {isLoading ? (
        <LottieIframe />
      ) : ["COMPLETED", "CANCELLED"].includes(apiResponse?.status) ? (
        <Box
          sx={{
            inset: "0 0 0 0",
            maxWidth: "480px",
            boxSizing: "border-box",
            margin: "0 auto",
            backgroundColor: "#fff",
            height: "100dvh",
            // border: "1px solid red",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box>
            <Stack alignItems={"center"} width={"auto"} gap={"20px"}>
              <img
                src={logoSvg}
                alt="logo img"
                style={{ width: "200px", marginBottom: "20px" }}
              />
            </Stack>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "proxima nova",
                fontSize: { xs: "15px", sm: "17px" },
                textAlign: "center",
                fontWeight: 500,
                textTransform: "capitalize",
                paddingInline: { xs: "8px", sm: "20px" },
              }}
            >
              {getStatusText(apiResponse?.status ?? "", apiResponse)}
            </Typography>
          </Box>
        </Box>
      ) : (
        <div
          style={{
            display: !isStatusValid ? "flex" : "none",
            backgroundColor: "#F7F9FC",
            border: "1px solid #ccc",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            maxWidth: "480px",
            margin: "auto",
            height: "100vh", // Make the container cover the entire viewport height
            width: "100vw", // Make the container cover the entire viewport width
            alignContent: "center", // Center content vertically
            justifyContent: "center", // Center content horizontally
            flexDirection: "column",
            overflowX: "hidden", // Stack content vertically
            fontFamily: "Proxima Nova",
            // overflowY: "scroll",
          }}
        >
          {/* Google Map Component (centered and sized like a phone screen) */}
          <div style={googleMapStyle}>
            {/* Google Map Component */}
            {Object.keys(apiResponse).length !== 0 ? (
              <GoogleMap style={{ flex: 1 }} apiResponse={apiResponse} />
            ) : (
              <></>
            )}
          </div>

          {/* Data Card (centered header)  */}

          <BottomCardHeader
            status={apiResponse?.status}
            message={apiResponse?.message}
            eta_in_min={apiResponse?.eta_in_min}
          />

          {/* Bottom Data Card (centered) */}
          {apiResponse?.status === "REQUESTED" && (
            <RequestedPhaseBottomCard
              apiResponse={apiResponse}
              dropOffTime={dropOffTime}
            />
          )}

          {apiResponse?.status === "ONGOING" && (
            <OngoingPhaseBottomCard
              apiResponse={apiResponse}
              dropOffTime={dropOffTime}
            />
          )}
        </div>
      )}

      {/* <AlertBar
        alertOpen={open}
        setAlertOpen={setOpen}
        message={alertMessage}
        alertType={alertType}
      /> */}
    </>
  );
};

export default RideTracking;
