import { Box, Typography } from "@mui/material";
import { useState, useEffect } from "react";

const containerStyle = {
  position: "relative",
  width: "100%",
  borderTopLeftRadius: "20px",
  borderTopRightRadius: "20px",
  height: "9.5%",
  display: "flex",
  alignItems: "center",
  padding: "0px 20px",
  boxSizing: "border-box",
};

const overlay = {
  position: "absolute",
  right: "5%",
  top: "0",
  background: "#F98244",
  padding: { xs: "2px 11px", sm: "4px 15px" },
  borderBottomLeftRadius: "10px",
  borderBottomRightRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // Corrected typo: justifyContainer -> justifyContent
  alignItems: "center",
};

const etaInMinStyle = {
  fontFamily: "Proxima Nova",
  fontSize: { xs: "1.2rem", sm: "16px", md: "18px" },
  fontWeight: 700,
  lineHeight: "24.36px",
  letterSpacing: "0.0015em",
  textAlign: "center",
  color: "#fff",
};

const minStyle = {
  fontSize: { xs: "1.2rem", sm: "16px", md: "18px" },
  fontFamily: "Proxima Nova",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.027px",
  color: "#fff",
};

const BottomCardHeader = ({ message, eta_in_min, status }) => {
  const [dynamicMessageIndex, setDynamicMessageIndex] = useState(0);
  const messages = [
    "You'll be reaching in",
    "Your destination is nearby",
    "You have arrived!",
    message,
  ];

  useEffect(() => {
    let timer;

    // Handle message changes based on status and eta_in_min
    if (status === "ONGOING") {
      if (eta_in_min > 2) {
        setDynamicMessageIndex(0);
      } else if (eta_in_min > 0 && eta_in_min <= 2) {
        setDynamicMessageIndex(1);
      } else {
        setDynamicMessageIndex(2);
      }
    } else if (status === "REQUESTED") {
      setDynamicMessageIndex(3);
    } else if (status === "COMPLETED") {
      setDynamicMessageIndex(2);
    }

    return () => {
      clearInterval(timer);
    };
  }, [status, eta_in_min]);

  const containerBackground = {
    background: "var(--brand-blue, #043A87)",
  };

  return (
    <Box style={{ ...containerStyle, ...containerBackground }}>
      <Typography sx={{ ...etaInMinStyle, marginBottom: "8px" }}>
        {messages[dynamicMessageIndex]}
      </Typography>
      <Box sx={overlay}>
        <Typography sx={etaInMinStyle}>{eta_in_min}</Typography>
        <Typography sx={minStyle}>{"min"}</Typography>
      </Box>
    </Box>
  );
};

export default BottomCardHeader;
